.header-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.header-cell {
    flex: 1;
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #FF9F43;
    color: "white"
}

.bg-green {
    background-color: green;
    border: none;

}

.bg-green:hover {
    background-color: green;
    border: none;
}

.bg-green:focus {
    background-color: rgb(7, 159, 7);
    border: none;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

.blinking-text {
    animation: blink 1s infinite;

}

.clickedPro .productset {
    border: 5px solid #FF9F43;
}
